import { http } from 'shared/api/http';
import { useMutation } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useRouter } from 'next/navigation';
import { useCallback, useEffect, useState } from 'react';

import * as yup from 'yup';
import { AxiosError } from 'axios';
import cookies from 'js-cookie';
import { sendGtmEvent } from 'shared/lib/send-gtm';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/nextjs';

const initialValues = {
  phone: '',
  pin: '',
};

export type LoginPayloadType = {
  login: string;
  password: string;
};

export type LoginResponseType = {
  user: {
    authenticationToken: string;
  };
};

const loginRequest = (data: LoginPayloadType) =>
  http.post<LoginResponseType>('/api/loans/v1/sessions', { user: data }).then((r) => {
    cookies.set('token', r.data.user.authenticationToken, { expires: 2 });
  });

export const useEnterPhonePinFormBg = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const onSuccess = useCallback(() => router.push('/choose-shop'), [router]);
  const onForgotPasswordClick = useCallback(() => {
    sendGtmEvent('login_forget');
    router.push('/auth/recovery');
  }, [router]);

  const [error, setError] = useState<string | null>(null);

  const { mutate, data, isPending } = useMutation({
    mutationFn: (payload: LoginPayloadType) => loginRequest(payload),
    onSuccess: () => {
      sendGtmEvent('login_enter', { result: 'success' });
      onSuccess();
    },
    onError: (err) => {
      sendGtmEvent('login_enter', { result: 'fail' });
      if (err instanceof AxiosError) {
        if (err.response) {
          if (err.response.status >= 500 && err.response.status < 600) {
            Sentry.captureException(err);
          }
        }
        setError(err.response?.data.errors.manager[0]);
      }
      setError('login error');
    },
  });

  const formik = useFormik({
    initialValues,
    validationSchema: yup.object({
      phone: yup
        .string()
        .matches(/^[^_]*$/, t('requiredField'))
        .required(t('requiredField')),
      pin: yup
        .string()
        .matches(/^\d{4}$/)
        .required(t('requiredField')),
    }),
    onSubmit: (values) => {
      setError(null);
      mutate({ login: values.phone.replace(/[+()\s]/g, ''), password: values.pin });
    },
  });

  useEffect(() => {
    sendGtmEvent('login_open');
  }, []);

  return {
    formik,
    isSubmitDisabled: !formik.isValid,
    isLoading: isPending,
    error,
    setError,
    onForgotPasswordClick,
  };
};
