import {
  ChangeEvent,
  Fragment,
  MouseEventHandler,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { AdditionalServiceCheckboxHelpIcon, ChevronRightIcon, HelpIcon } from 'shared/ui/icon';
import Divider from '@mui/material/Divider';
import { Checkbox, Switch } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { ChevronRight } from '@mui/icons-material';
import { useTranslation, Trans } from 'react-i18next';

const Title = ({ children }: PropsWithChildren<{}>) => {
  return (
    <Box>
      <Typography variant="h2" component="h2">
        {children}
      </Typography>
    </Box>
  );
};

const Description = ({ children }: PropsWithChildren<{}>) => {
  return (
    <Box
      sx={{
        fontSize: '16px',
        lineHeight: '20px',
      }}
    >
      {children}
    </Box>
  );
};

type Props = {
  phone: string;
};

const ConfirmationCodeInfo = ({ phone }: Props) => {
  return (
    <Box
      sx={{
        fontSize: '16px',
        lineHeight: '20px',
        textAlign: 'center',
        '& > span': {
          fontWeight: 700,
          fontVariant: 'tabular-nums',
          whiteSpace: 'nowrap',
        },
      }}
    >
      <Trans
        i18nKey="forgot-pin.confirmationCodeInfo"
        components={{
          span: (
            <span
              dangerouslySetInnerHTML={{
                __html: phone,
              }}
            />
          ),
        }}
      />
    </Box>
  );
};

type RepeatConfirmationCodeProps = {
  phone: string;
  delay?: number;
  onFinish?: () => void;
};

export const RepeatConfirmationCode = ({
  phone,
  onFinish,
  delay = 30,
}: RepeatConfirmationCodeProps) => {
  const [count, setCount] = useState(delay);

  useEffect(() => {
    if (count > 0) {
      const timer = setInterval(() => setCount(count - 1), 1000);
      return () => {
        clearInterval(timer);
      };
    }

    if (count === 0) {
      onFinish && onFinish();
    }
  }, [count, onFinish]);

  if (count === 0) {
    return null;
  }

  return (
    <Box
      sx={{
        fontSize: '14px',
        lineHeight: '18px',
        textAlign: 'center',
        '& > div:first-of-type': {
          fontWeight: 700,
          fontVariant: 'tabular-nums',
          whiteSpace: 'nowrap',
        },
      }}
    >
      {phone ? (
        <Trans i18nKey="forgot-pin.repeatConfirmationCode" values={{ phone }} />
      ) : (
        <Trans
          i18nKey="forgot-pin.repeatConfirmationCodeWithoutPhone"
          components={[<span key={0} />]}
          values={{
            phone,
          }}
        />
      )}
      <Box
        sx={{
          fontSize: '22px',
          lineHeight: '24px',
          color: '#FF5A1E',
          fontVariant: 'tabular-nums',
        }}
      >
        00:{count >= 10 ? count : '0' + count}
      </Box>
    </Box>
  );
};

type SendBarcodeCodeProps = {
  delay?: number;
  onSend?: () => void;
};

export const SendBarcode = ({ onSend, delay = 10 }: SendBarcodeCodeProps) => {
  const { t } = useTranslation();
  const [count, setCount] = useState(0);
  const [attempt, setAttempt] = useState(0);

  const handleClickFirstTime = () => {
    setAttempt(attempt + 1);
    onSend && onSend();
    setCount(delay);
  };

  useEffect(() => {
    const timer = count > 0 && setInterval(() => setCount(count - 1), 1000);
    return () => {
      // @ts-ignore
      clearInterval(timer);
    };
  }, [count]);

  return (
    <Box
      sx={{
        pointerEvents: count > 0 ? 'none' : 'auto',
      }}
    >
      <Box
        sx={{
          textTransform: 'uppercase',
          textAlign: 'center',
          fontWeight: 500,
          cursor: 'pointer',
          userSelect: 'none',
        }}
      >
        {attempt === 0 ? (
          <Box onClick={handleClickFirstTime}>{t('send-barcode.label.first-time')}</Box>
        ) : (
          <Box
            sx={{
              color: count === 0 ? 'inherit' : '#8B8D92',
            }}
            onClick={() => {
              if (count === 0) {
                !count && onSend && onSend();
                setCount(delay);
              }
            }}
          >
            {t('send-barcode.label.repeat')}
          </Box>
        )}
      </Box>
      {!!attempt && (
        <Box
          mt={1}
          sx={{
            fontSize: '14px',
            lineHeight: '18px',
            fontVariant: 'tabular-nums',
            textAlign: 'center',
            visibility: count > 0 ? 'visible' : 'hidden',
            userSelect: 'none',
          }}
        >
          {t('send-barcode.countdown', { count })}
        </Box>
      )}
    </Box>
  );
};

type LegalListProps = {
  items: {
    title: string;
    href: string;
  }[];
  onClick: (params: { title: string; href: string }) => void;
};

const LegalList = ({ items, onClick }: LegalListProps) => {
  if (items.length === 0) return null;
  return (
    <Box>
      {items.map(({ title, href }, index) => (
        <Fragment key={index}>
          {!!index && (
            <Box my={0.5}>
              <Divider />
            </Box>
          )}
          <Button
            onClick={() => {
              onClick({ title, href });
            }}
            fullWidth
            sx={{
              textAlign: 'left',
              color: 'inherit',
              textTransform: 'none',
              fontSize: '12px',
              lineHeight: '16px',
              px: 0,
            }}
          >
            <Box
              component={'span'}
              sx={{
                gap: 6,
                flex: 1,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flex: 1,
                  gap: 1.5,
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    width: 24,
                    height: 24,
                  }}
                >
                  <HelpIcon color={'#ccc'} />
                </Box>
                <Box
                  component={'span'}
                  sx={{
                    flexGrow: 1,
                  }}
                >
                  {title}
                </Box>
              </Box>
              <Box
                sx={{
                  width: 24,
                  height: 24,
                }}
              >
                <ChevronRightIcon />
              </Box>
            </Box>
          </Button>
        </Fragment>
      ))}
    </Box>
  );
};

type ProgressSubmitProps = {
  theme?: 'primary' | 'default';
  progress?: number;
  disabled?: boolean;
  onClick?: () => void;
};

const ProgressSubmit = ({
  progress = 0,
  children,
  theme = 'primary',
  disabled,
  onClick,
}: PropsWithChildren<ProgressSubmitProps>) => {
  return (
    <Box
      pb={4}
      sx={{
        '@media (min-width: 720px)': {
          maxWidth: '720px',
          pb: 0,
        },
      }}
    >
      <Button
        disabled={disabled}
        type="submit"
        onClick={onClick}
        sx={{
          color: theme === 'primary' ? '#FFF !important' : 'inherit',
          borderRadius: 0,
          position: 'relative',
          backgroundColor:
            theme === 'primary'
              ? [0, 1].includes(progress)
                ? '#FF5A1E'
                : '#b23e15'
              : 'transparent',
          opacity: 1,
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            width: progress * 100 + '%',
            transition: '.3s',
            backgroundColor: '#FF5A1E',
            zIndex: 0,
          },
        }}
        variant={theme === 'primary' ? 'contained' : 'text'}
        disableElevation
        fullWidth
      >
        <Box
          sx={{
            position: 'relative',
            zIndex: 1,
            lineClamp: '1',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
        >
          {children}
        </Box>
      </Button>
    </Box>
  );
};

type LegalCheckboxProps = {
  checked: boolean;
  href?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  onDownload?: () => void;
};

export const LegalCheckbox = ({
  children,
  href,
  checked,
  onChange,
  onDownload,
}: PropsWithChildren<LegalCheckboxProps>) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1.5,
      }}
    >
      <Box
        component={'div'}
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1.5,
          flex: 1,
        }}
      >
        <Checkbox
          checked={checked || false}
          onChange={onChange}
          sx={{
            px: '0',
          }}
        />
        <Box
          component={'span'}
          sx={{
            fontSize: '12px',
            lineHeight: '16px',
            color: '#8B8D92',
          }}
        >
          {children}
        </Box>
      </Box>
      {href && (
        <IconButton
          href={href}
          sx={{
            color: '#323232',
            px: 0,
          }}
        >
          <ChevronRight width={24} height={24} />
        </IconButton>
      )}
      {onDownload && (
        <IconButton
          onClick={onDownload}
          sx={{
            color: '#323232',
            px: 0,
            width: '24px',
            height: '24px',
          }}
        >
          <ChevronRight width={24} height={24} />
        </IconButton>
      )}
    </Box>
  );
};

const AdditionalServiceCheckbox = ({
  children,
  onHelp,
  enable,
  disabled = false,
  onChange,
  onDownload,
}: PropsWithChildren<{
  onHelp?: MouseEventHandler;
  enable: boolean;
  disabled?: boolean;
  onChange: () => void;
  onDownload?: () => void;
}>) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1.5,
      }}
    >
      <Box
        component={'div'}
        sx={{
          flex: 1,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Switch disabled={disabled} defaultChecked checked={enable} onChange={onChange} />
        <Box
          sx={{
            fontSize: '14px',
            lineHeight: '18px',
            cursor: 'pointer',
          }}
          onClick={onHelp}
        >
          {children}
          <AdditionalServiceCheckboxHelpIcon
            width={14}
            height={14}
            style={{
              marginLeft: '6px',
              cursor: 'pointer',
            }}
          />
        </Box>
      </Box>
      {typeof onDownload === 'function' && (
        <IconButton
          sx={{
            px: 0,
            width: '24px',
            height: '24px',
          }}
        >
          <ChevronRight width={24} height={24} onClick={onDownload} />
        </IconButton>
      )}
    </Box>
  );
};

type ForgotPasswordButtonProps = {
  onClick?: MouseEventHandler;
};

const ForgotPasswordButton = ({ onClick }: ForgotPasswordButtonProps) => {
  const { t } = useTranslation();
  return (
    <Button
      fullWidth
      onClick={onClick}
      sx={{
        color: 'inherit',
      }}
    >
      {t('sign-in.forgot-pin')}
    </Button>
  );
};

const SignUpButton = () => {
  const { t } = useTranslation();
  return (
    <Button
      fullWidth
      href={t('loginIntroPage.actions.signUp.href')}
      sx={{
        color: 'inherit',
      }}
    >
      {t('loginIntroPage.actions.signUp.title')}
    </Button>
  );
};

type ForgotLoginButtonProps = {
  onClick?: MouseEventHandler;
};

const ForgotLoginButton = ({ onClick }: ForgotLoginButtonProps) => {
  const { t } = useTranslation();
  return (
    <Button
      fullWidth
      onClick={onClick}
      sx={{
        color: 'inherit',
      }}
    >
      {t('sign-in.forgot-login')}
    </Button>
  );
};

type LayoutProps = {
  size?: 'sm' | 'md';
};

const Layout = ({ children, size = 'md' }: PropsWithChildren<LayoutProps>) => {
  const sizeMap: Record<'sm' | 'md', string> = {
    md: '510px',
    sm: '328px',
  };
  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: sizeMap[size],
      }}
    >
      {children}
    </Box>
  );
};

type ActionButtonProps = {
  title: string;
  description: string;
  onClick: () => void;
  icon: JSX.Element;
};

export const ActionButton = ({ title, description, onClick, icon }: ActionButtonProps) => {
  return (
    <Box
      sx={{
        cursor: 'pointer',
        textAlign: 'center',
        '&:hover': {
          color: '#FF5A1E',
        },
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '8px',
        }}
      >
        {icon}
        <Box
          component={'span'}
          sx={{
            fontSize: '16px',
            lineHeight: '20px',
            textTransform: 'uppercase',
          }}
        >
          {title}
        </Box>
      </Box>
      <Box
        mt={1}
        sx={{
          fontSize: '14px',
          lineHeight: '20px',
          color: '#8B8D92',
        }}
      >
        {description}
      </Box>
    </Box>
  );
};

export const Form = {
  ForgotLoginButton,
  Title,
  Description,
  ConfirmationCodeInfo,
  RepeatConfirmationCode,
  LegalList,
  ProgressSubmit,
  LegalCheckbox,
  AdditionalServiceCheckbox,
  ForgotPasswordButton,
  SendBarcode,
  Layout,
  SignUpButton,
  ActionButton,
};
